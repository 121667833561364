import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/style.scss';
import { client_id, client_secret } from './constant/info';
import { callApiOauthToken } from './constant/apiCall';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));
const Login = React.lazy(() => import('./views/login/Login'));

const OauthToken = () => {
  if(localStorage.getItem('access_token')==null){
    callApiOauthToken('/oauth/token', 'post' ,
        {
            grant_type: 'client_credentials',
            client_id: client_id,
            client_secret: client_secret
        }
    ).then(res=>{
        //console.log(res)
        localStorage.setItem('access_token', res.data.access_token);
    })
    .catch(err=>{
        console.log(err);
    }).finally(()=>{
        //setloading(false);
    })
  }

  return true
}

class App extends Component {



  render() {

    return (
      <BrowserRouter>
          <OauthToken />
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
            </Switch>
          </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
