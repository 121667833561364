export const logo = ['96 35', `
  <title>bhaban react pro</title>
  <g transform="translate(0.000000,35.000000) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M86 331 c-16 -18 -23 -81 -8 -81 17 0 32 25 32 53 l0 28 31 -22 c31
-22 31 -22 27 -100 -5 -84 -9 -76 55 -107 4 -2 7 1 7 7 0 6 -15 18 -32 27 -18
10 -27 19 -20 21 8 3 12 26 12 68 0 61 -2 65 -32 88 -50 37 -54 38 -72 18z"/>
<path d="M62 224 c-34 -20 -42 -31 -42 -55 0 -16 5 -29 10 -29 6 0 10 12 10
26 0 22 32 62 50 64 3 0 4 -52 2 -115 -3 -99 -1 -115 12 -115 13 0 16 13 16
70 0 52 4 71 16 78 22 12 15 71 -11 88 -18 12 -26 10 -63 -12z"/>
<path d="M320 200 c0 -6 41 -10 103 -11 97 -1 100 -1 70 -15 -55 -23 -37 -87
26 -96 24 -3 25 -1 22 55 l-2 57 45 0 c25 0 46 -4 46 -10 0 -5 -11 -10 -24
-10 -49 0 -62 -59 -18 -88 20 -13 22 -13 22 2 0 9 -7 16 -15 16 -9 0 -15 10
-15 26 0 21 4 25 23 22 18 -2 23 -10 25 -40 5 -58 22 -45 22 16 0 30 3 61 6
70 5 14 -13 16 -165 16 -107 0 -171 -4 -171 -10z m210 -70 c0 -45 -10 -50 -40
-20 -26 26 -25 33 3 46 35 18 37 16 37 -26z"/>
<path d="M710 200 c0 -5 17 -10 38 -11 l37 -1 -32 -14 c-55 -24 -37 -87 27
-96 24 -3 25 0 26 44 0 27 5 47 10 45 5 -1 8 -15 7 -30 0 -17 3 -27 10 -24 17
5 21 45 7 63 -10 11 -9 14 3 14 19 0 28 -23 21 -58 -2 -15 -1 -32 3 -39 11
-16 45 -20 50 -5 3 7 -4 12 -16 12 -22 0 -30 33 -12 44 21 13 41 -7 41 -40 0
-19 5 -34 10 -34 6 0 10 24 10 54 0 30 3 61 6 70 5 14 -9 16 -120 16 -77 0
-126 -4 -126 -10z m220 -26 c0 -10 -7 -14 -22 -12 -32 5 -35 28 -4 28 17 0 26
-5 26 -16z m-144 -82 c-8 -7 -46 28 -46 43 0 7 10 16 23 22 20 9 22 7 25 -24
2 -19 1 -38 -2 -41z"/>
<path d="M370 149 c0 -28 3 -31 25 -27 28 6 32 -3 10 -22 -8 -6 -23 -9 -35 -5
-15 5 -20 15 -20 41 0 19 -4 34 -10 34 -5 0 -10 -16 -10 -35 0 -26 6 -39 26
-52 24 -15 29 -15 53 -2 48 27 35 99 -18 99 -17 0 -21 -6 -21 -31z m50 0 c0
-5 -7 -9 -15 -9 -8 0 -15 7 -15 15 0 9 6 12 15 9 8 -4 15 -10 15 -15z"/>
<path d="M14 108 c-5 -7 -7 -33 -4 -58 4 -33 10 -46 23 -48 14 -2 17 5 17 46
0 26 5 53 12 60 9 9 6 12 -14 12 -14 0 -30 -6 -34 -12z"/>
<path d="M160 111 c0 -6 13 -17 30 -26 34 -18 44 -19 35 -5 -10 16 -65 42 -65
31z"/>
<path d="M670 95 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"/>
<path d="M160 76 c0 -2 16 -13 35 -25 19 -11 35 -16 35 -11 0 5 -13 16 -30 25
-30 15 -40 18 -40 11z"/>
<path d="M160 42 c0 -7 14 -16 63 -40 4 -2 7 0 7 6 0 7 -14 16 -62 40 -5 2 -8
0 -8 -6z"/>
<path d="M160 10 c0 -6 7 -10 15 -10 8 0 15 2 15 4 0 2 -7 6 -15 10 -8 3 -15
1 -15 -4z"/>
</g>
`]
