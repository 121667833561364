import axios from 'axios';
// import { showNotification } from './notification';
// import { isNil } from 'ramda';
import { baseUrl } from './info';



export const callApi =  async (url, method,data,) => {
    url=baseUrl+url
    const getUserInfo = JSON.parse(localStorage.getItem('user_info'));
    //console.log(data)
    const getToken = getUserInfo.token;
    try {
        const res = await axios({
            method,
            url,
            data,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${getToken}`
            }
        });
        //console.log(res)
        return res;
    } catch (error) {
        console.log(error)
    }
}

export const callApiForData =  async (url, method, data, params,) => {
  //console.log(url,method,data,params)
  const getUserInfo = JSON.parse(localStorage.getItem('user_info'));
  const getToken = getUserInfo.token;
  try {
    const res = await axios({
      method,
      baseURL: baseUrl,
      url,
      data,
      params,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${getToken}`
      }
    });
    return res;
  } catch (error) {
    console.log(error)
    // if (!isNil(error.response)) {
    //     showNotification('Failed', `${error.response.statusText}`, 'danger');
    // }
    // throw error;
  }
}


export const callApiAuth =  async (url, method, data, params,) => {
    try {
        const res = await axios({
            method,
            baseURL: baseUrl,
            url,
            data,
            params,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            }
        });
        // method == 'get' || showNotification('Success', 'Successful', 'success');
        return res;
    } catch (error) {

        // if (!isNil(error.response)) {
        //     showNotification('Failed', `${error.response.statusText}`, 'danger');
        // }
        // throw error;
    }
}

export const callApiOauthToken =  async (url, method, data, params,) => {
    try {
        const res = await axios({
            method,
            baseURL: baseUrl,
            url,
            data,
            params,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        return res;
    } catch (error) {

        // if (!isNil(error.response)) {
        //     showNotification('Failed', `${error.response.statusText}`, 'danger');
        // }
        // throw error;
    }
}


